import request from '../../utils/request'

//获取菜单树结构
export function getMenuTree(): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/menu/tree',
  })
}
//点击菜单节点，根据id获取信息
export function getNodeData(id: string | number): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/menu/' + id,
  })
}

interface Form<T> {
  permission: T;
  name: T;
  menuId: T;
  parentId: T;
  icon: T;
  sort: T;
  component: T;
  type: T;
  path: T;
}
//更新菜单信息,form就是单个菜单节点都甩到后端，参数可能会比定义的接口类多
export function updateMenu(form: Form<string>): Promise<any> {
  return request({
    url: 'admin/menu',
    method: 'put',
    data: form
  })
}
//新增保存菜单信息，这个接口类参数就是完整的了
export function saveMenu(form: Form<string>): Promise<any> {
  return request({
    url: 'admin/menu',
    method: 'post',
    data: form
  })
}
//根据菜单节点id进行删除操作
export function deleteMenu(id: string | number) {
  return request({
    url: 'admin/menu/' + id,
    method: 'delete'
  })
}