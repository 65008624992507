
























































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import {
  getMenuTree,
  getNodeData,
  updateMenu,
  saveMenu,
  deleteMenu,
} from "@/serve/permissionsManage/menuManage";
import { Tree } from "element-ui";

interface FormData {
  topic: string;
  standardType: string;
  sort: number | string | undefined;
  topicType: string;
  more: string;
  [index: string]: any;
}
@Component({
  components: {},
  name: "MenuManage",
  filters: {
    typeFilter(type: number): string {
      const typeMap = {
        0: "菜单",
        1: "按钮",
      };
      return (typeMap as any)[type];
    },
  },
})
export default class MenuManage extends Vue {
  @Watch("filterText")
  changeText(val: string) {
    (this.$refs.tree as Tree).filter(val);
  }
  public filterText = "";
  public defaultProps = {
    children: "children",
    label: "label",
  };
  public form = {
    permission: "",
    name: "",
    menuId: "",
    parentId: "",
    icon: "",
    sort: "",
    component: "",
    type: "",
    path: "",
  };
  public formEdit = true;
  public formAdd = true;
  public formStatus = "";
  public typeOptions = ["0", "1"];
  public currentId = -1;
  public menuData = [];
  public expanded: string[] = [];
  public created() {
    this.getMenuTree();
  }
  //获取菜单树结构
  public async getMenuTree() {
    const res = await getMenuTree();
    this.menuData = res.data;
    // console.log('resasd :>> ', res);
  }
  //点击新增按钮
  public handlerAdd() {
    this.resetForm();
    this.formEdit = false;
    this.formStatus = "create";
  }
  //点击编辑按钮
  public handlerEdit() {
    if (this.form.menuId) {
      this.formEdit = false;
      this.formStatus = "update";
    }
  }
  //点击删除按钮
  public handleDelete() {
    (this as any).$messageBox
      .confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        deleteMenu(this.currentId).then((res) => {
          this.getMenuTree();
          this.resetForm();
          this.onCancel();
          res.data
            ? this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success",
                duration: 2000,
              })
            : this.$notify({
                title: "失败",
                message: res.msg,
                type: "warning",
                duration: 2000,
              });
        });
      });
  }
  //点击取消按钮
  public onCancel() {
    this.formEdit = true;
    this.formStatus = "";
  }
  //展开某个树节点
  public nodeExpand(data: { id: any }) {
    this.expanded.push(data.id);
  }
  //关闭某个树节点
  public nodeCollapse(data: { id: string }) {
    this.expanded.splice(this.expanded.indexOf(data.id), 1);
  }
  //进行搜索菜单节点
  public filterNode(value: any, data: { name: string | any[] }) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }
  //清空表单数据
  public resetForm() {
    // (this.$refs.ruleForm as ElForm).resetFields();不知道为什么不生效
    this.form = {
      permission: "",
      name: "",
      menuId: "",
      parentId: `${this.currentId}`,
      icon: "",
      sort: "",
      component: "",
      type: "",
      path: "",
    };
  }
  //点击菜单节点，获取对应的菜单信息
  public async getNodeData(data: any): Promise<void> {
    console.log("data :>> ", data);
    if (!this.formEdit) {
      this.formStatus = "update";
    }
    const res = await getNodeData(data.id);
    this.form = res.data;
    this.currentId = data.id;
  }
  //更新单个菜单信息
  public update() {
    updateMenu(this.form).then((res) => {
      this.getMenuTree();
     if(res.code===200){
        this.$notify({
        title: "成功",
        message: "更新成功",
        type: "success",
        duration: 2000,
      });
     }
    });
  }
  ////保存新增菜单信息
  public save() {
    saveMenu(this.form).then((res) => {
      this.getMenuTree();
     if(res.code===200){
        this.$notify({
        title: "成功",
        message: "更新成功",
        type: "success",
        duration: 2000,
      });
     }
    });
  }
}
